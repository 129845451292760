import colors from '@/colors/colors.module.scss';

export const paletteCategorical = [
  colors.$c1,
  colors.$c2,
  colors.$c3,
  colors.$c4,
  colors.$c5,
  colors.$c6,
  colors.$c7,
  colors.$c8,
  colors.$c9,
  colors.$c10,
  colors.$cOthers,
];

export const paletteSequentialPrimary = [
  colors.$sp1,
  colors.$sp2,
  colors.$sp3,
  colors.$sp4,
  colors.$sp5,
  colors.$sp6,
  colors.$sp7,
  colors.$sp8,
  colors.$sp9,
];

export const paletteSequentialSecondary = [
  colors.$ss1,
  colors.$ss2,
  colors.$ss3,
  colors.$ss4,
  colors.$ss5,
  colors.$ss6,
  colors.$ss7,
  colors.$ss8,
  colors.$ss9,
];

export const paletteSequentialTertiary = [
  colors.$st1,
  colors.$st2,
  colors.$st3,
  colors.$st4,
  colors.$st5,
  colors.$st6,
  colors.$st7,
  colors.$st8,
  colors.$st9,
];

export const paletteDivergingPrimary = [
  colors.$dp4,
  colors.$dp3,
  colors.$dp2,
  colors.$dp1,
  colors.$dp0,
  colors.$dpn1,
  colors.$dpn2,
  colors.$dpn3,
  colors.$dpn4,
];

export const paletteDivergingSecondary = [
  colors.$ds4,
  colors.$ds3,
  colors.$ds2,
  colors.$ds1,
  colors.$ds0,
  colors.$dsn1,
  colors.$dsn2,
  colors.$dsn3,
  colors.$dsn4,
];

export const paletteDivergingTertiary = [
  colors.$dt4,
  colors.$dt3,
  colors.$dt2,
  colors.$dt1,
  colors.$dt0,
  colors.$dtn1,
  colors.$dtn2,
  colors.$dtn3,
  colors.$dtn4,
];

export const paletteThreeFactors = [
  colors.$positive,
  colors.$neutral,
  colors.$negative,
];
