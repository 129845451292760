import { MLAPIRequest, postRequest } from '@/helpers/request';
import { MLAPI_ROOT } from '@/helpers/environment_variables';
import { createMLUrl } from '@/store/modules/smart-advisor/smart-advisor-context';
import colors from '@/colors/colors.module.scss';
import { paletteCategorical } from '@/colors/chart-palettes';

const MLAPI_COT_DATA = 'commitment_of_traders/fetch';
const MLAPI_INTEREST_RATE_DATA = 'interest_rates/fetch';
const MLAPI_FUTURES_PRICES = 'futures-prices';
const MLAPI_INDICATOR_SIGNALS = 'indicator-signals';

const CURRENT_YEAR = new Date().getFullYear();

function processPricesForCommodity(priceData) {
  const dates = Object.keys(priceData.close).sort((a, b) => new Date(a) - new Date(b));

  return {
    dates,
    open: dates?.map((ts) => priceData.open[ts]),
    close: dates?.map((ts) => priceData.close[ts]),
    high: dates?.map((ts) => priceData.high[ts]),
    low: dates?.map((ts) => priceData.low[ts]),
  };
}

export default {
  namespaced: true,
  state: () => ({
    allCommodities: [],
    commodityAddEditModalOpen: false,
    deliveryAddEditModalOpen: false,
    commodityRecords: null,
    deliveryRecords: null,
    selectedIndicatorSignal: null,
    selectedCommodity: null,
    verifiedCommentary: [],
    unverifiedCommentary: [],
    // TODO: Remove the hardcoding of selectedCurrency once we are supporting multiple currencies
    selectedCurrency: 'CAD',
    selectedIndicator: null,
    buyerSmartAdvisorCotData: {},
    buyerSmartAdvisorInterestRateData: {},
    buyerSmartAdvisorFuturesPrices: {},
    buyerSmartAdvisorIndicatorSignals: {},
    availableConclusions: ['HOLD', 'BUY'],
    availableSignalStrengths: Array.from({ length: 10 }, (_, i) => i + 1),
    indicators: [
      'RSI',
      'Bollinger Bands',
      'MACD',
      'Simple Moving Average',
      'Commitment of Traders',
      'Interest Rates',
    ],
    indicatorSignals: [
      'Price Action',
      'Technical',
      'Time Trigger',
      'Funds',
      'Relative Value',
      'Dominant Force',
    ],
    chartData: {},

    CHARTS: [{
      mode: 'multiyear',
      name: 'Price Action',
    }, {
      name: 'Technical',
      mode: 'subchart',
      types: ['line', 'mountain', 'candlestick'],
      subCharts: ['RSI', 'Bollinger Bands', 'MACD', 'Simple Moving Average'],
      defaultRange: '1m',
    }, {
      name: 'Time Trigger',
      mode: 'multiyear',
    }, {
      name: 'Funds',
      mode: 'subchart',
      types: ['line', 'mountain', 'candlestick'],
      subCharts: ['Commitment of Traders'],
      hideLatestPrice: true,
      defaultRange: '1m',
    }, {
      name: 'Relative Value',
      mode: 'relative',
      defaultRange: '1m',
    }, {
      name: 'Dominant Force',
      mode: 'subchart',
      types: ['line', 'mountain', 'candlestick'],
      subCharts: ['Interest Rates'],
      defaultRange: 'max',
    }],
    selectedChartConfig: null,
    selectedChartOptions: null,

    additionalYearsConfig: Array.from({ length: 9 }, (_, i) => CURRENT_YEAR - i).map((year, index) => ({
      text: year,
      value: index,
      ...(index === 0 ? { disabled: true } : null),
      color: paletteCategorical[index % paletteCategorical.length],
    })),
    chartTypesConfig: [{
      text: 'Line Chart',
      type: 'line',
      iconUrl: '/static/organization_profile/svg/buyer-smart-advisor-line-chart.svg',
      value: 0,
    }, {
      text: 'Mountain Chart',
      type: 'mountain',
      iconUrl: '/static/organization_profile/svg/buyer-smart-advisor-mountain-chart.svg',
      value: 1,
    }, {
      text: 'Candlestick Chart',
      type: 'candlestick',
      iconUrl: '/static/organization_profile/svg/buyer-smart-advisor-candlestick-chart.svg',
      value: 2,
    }],
    chartRangeConfig: [{
      value: '1M',
      text: 'Past Month',
    }, {
      value: '6M',
      text: 'Past 6 Months',
    }, {
      value: 'YTD',
      text: 'Year to Date',
    }, {
      value: '1Y',
      text: 'Past 1 Year',
    }, {
      value: '5Y',
      text: 'Past 5 Years',
    }, {
      value: 'MAX',
      text: 'All Time',
    }],
    subChartConfig: [{
      text: 'RSI',
      value: 0,
    }, {
      text: 'Bollinger Bands',
      value: 1,
    }, {
      text: 'MACD',
      value: 2,
    }, {
      text: 'Simple Moving Average',
      value: 3,
    }, {
      text: 'Commitment of Traders',
      value: 4,
    }, {
      text: 'Interest Rates',
      value: 5,
    }],
  }),
  mutations: {
    setVerifiedCommentary(state, value) {
      state.verifiedCommentary = value;
    },
    setUnverifiedCommentary(state, value) {
      state.unverifiedCommentary = value;
    },
    setAllCommodities(state, commodities) {
      state.allCommodities = commodities;
    },
    setCommodityAddEditModalOpen(state, value) {
      state.commodityAddEditModalOpen = value;
    },
    setDeliveryAddEditModalOpen(state, value) {
      state.deliveryAddEditModalOpen = value;
    },
    setCommodityRecords(state, value) {
      state.commodityRecords = value;
    },
    setDeliveryRecords(state, value) {
      state.deliveryRecords = value;
    },
    setSelectedCommodity(state, value) {
      state.selectedCommodity = value;
    },
    setSelectedCurrency(state, value) {
      state.selectedCurrency = value;
    },
    setSelectedIndicator(state, value) {
      state.selectedIndicator = value;
    },
    setBuyerSmartAdvisorCotData(state, value) {
      state.buyerSmartAdvisorCotData = value;
    },
    setBuyerSmartAdvisorInterestRateData(state, value) {
      state.buyerSmartAdvisorInterestRateData = value;
    },
    setChartData(state, value) {
      state.chartData = value;
    },
    setBuyerSmartAdvisorFuturesPrices(state, value) {
      state.buyerSmartAdvisorFuturesPrices = value;
    },
    setBuyerSmartAdvisorIndicatorSignals(state, value) {
      state.buyerSmartAdvisorIndicatorSignals = value;
    },
    setSelectedIndicatorSignal(state, value) {
      state.selectedIndicatorSignal = value;
    },
    setSelectedChartConfig(state, value) {
      state.selectedChartConfig = value;
    },
    setSelectedChartOptions(state, { key, value }) {
      state.selectedChartOptions = {
        ...(state.selectedChartOptions || {}),
        [key]: value,
      };
    },
  },

  getters: {
    getConclusionColor: () => (conclusion) => {
      const color = { HOLD: colors.$neutral, BUY: colors.$positive };
      return color[conclusion] || 'inherit';
    },

    getProgressColor: () => (value) => {
      if (value === 0) return colors.$greyLighten2;
      return value >= 6 ? colors.$positive : colors.$neutral;
    },

    relatedCommodity: (state) => {
      if (!state.selectedCommodity) return null;

      const fullCommodity = state.allCommodities.find(
        (commodity) => commodity.ml_name === state.selectedCommodity.value,
      );

      return fullCommodity
        ? state.allCommodities.find((commodity) => commodity.ml_name === fullCommodity.related_ml_commodity) || null
        : null;
    },

    hasChartData: (state) => Boolean(state.chartData?.ohlc?.close?.length)
      && Boolean(state.chartData?.ohlc?.dates?.length),

    selectedChartYears: (state) => {
      if (!state.selectedChartOptions?.years?.length) return null;
      return state.additionalYearsConfig.filter((year, index) => state.selectedChartOptions.years.includes(index));
    },

    selectedChartType: (state) => {
      if (!('type' in state.selectedChartOptions)) return null;
      return state.chartTypesConfig.find((config) => config.value === state.selectedChartOptions.type);
    },

    selectedChartRange: (state) => {
      const today = new Date();
      const startOfYear = new Date(CURRENT_YEAR, 0, 1);
      let startDate;
      let endDate;

      switch (state.chartRangeConfig[state.selectedChartOptions.range].value) {
        case '1M':
          startDate = new Date(today);
          startDate.setDate(today.getDate() - 31);
          endDate = today;
          break;
        case '6M':
          startDate = new Date(today);
          startDate.setDate(today.getDate() - 31 * 6);
          endDate = today;
          break;
        case 'YTD':
          startDate = startOfYear;
          endDate = today;
          break;
        case '1Y':
          startDate = new Date(today);
          startDate.setFullYear(today.getFullYear() - 1);
          endDate = today;
          break;
        case '5Y':
          startDate = new Date(today);
          startDate.setFullYear(today.getFullYear() - 5);
          endDate = today;
          break;
        case 'MAX':
          startDate = new Date(2015, 0, 1);
          endDate = today;
          break;
        default:
          startDate = today;
          endDate = today;
      }

      return [
        startDate.toISOString().split('T')[0],
        endDate.toISOString().split('T')[0],
      ];
    },

    selectedSubChart: (state) => {
      if (!('subChart' in state.selectedChartOptions)) return null;
      return state.subChartConfig.find((config) => config.value === state.selectedChartOptions.subChart);
    },
  },

  actions: {
    openCommodityAddEditModal({ commit }) {
      commit('setCommodityAddEditModalOpen', true);
    },

    openDeliveryAddEditModal({ commit }) {
      commit('setDeliveryAddEditModalOpen', true);
    },

    switchChart({ commit, state }, indicator) {
      commit('setSelectedIndicatorSignal', indicator);

      const chartConfig = state.CHARTS.find((chart) => chart.name === indicator);
      commit('setSelectedChartConfig', chartConfig);

      commit('setSelectedChartOptions', {
        key: 'years',
        value: [0, 1, 2],
      });

      commit('setSelectedChartOptions', {
        key: 'type',
        value: chartConfig?.types?.length
          ? state.chartTypesConfig.find((config) => config.type === chartConfig.types[0]).value
          : 0,
      });

      commit('setSelectedChartOptions', {
        key: 'range',
        value: chartConfig?.defaultRange
          ? state.chartRangeConfig.findIndex((config) => config.value === chartConfig.defaultRange.toUpperCase())
          : 0,
      });

      commit('setSelectedChartOptions', {
        key: 'subChart',
        value: chartConfig?.subCharts?.length
          ? state.subChartConfig.find((config) => config.text === chartConfig.subCharts[0]).value
          : 0,
      });
    },

    async fetchAllCommodities({ commit }) {
      try {
        const commodities = await postRequest('/budgeting/buyer_advisor/commodities/');
        commit('setAllCommodities', commodities.commodities);
        return commodities.commodities;
      } catch (error) {
        this._vm.$snackbar.error(error);
        return null;
      }
    },

    async createCommodity({ dispatch }, payload) {
      try {
        await postRequest('/budgeting/buyer_advisor/create_commodity/', payload);
        await dispatch('getCommoditiesByOrganization');
        this._vm.$snackbar.success('Commodity created successfully');
        return true;
      } catch (error) {
        this._vm.$snackbar.error(error);
        return false;
      }
    },

    async updateCommodity({ dispatch }, payload) {
      try {
        await postRequest('/budgeting/buyer_advisor/update_commodity/', payload);
        await dispatch('getCommoditiesByOrganization');
        this._vm.$snackbar.success('Commodity updated successfully');
        return true;
      } catch (error) {
        this._vm.$snackbar.error(error);
        return false;
      }
    },

    async deleteCommodity({ dispatch }, payload) {
      try {
        await postRequest('/budgeting/buyer_advisor/delete_commodity/', payload);
        await dispatch('getCommoditiesByOrganization');
        this._vm.$snackbar.success('Commodity deleted successfully');
        return true;
      } catch (error) {
        this._vm.$snackbar.error(error);
        return false;
      }
    },

    async getCommoditiesByOrganization({ commit }) {
      try {
        const { commodities } = await postRequest('/budgeting/buyer_advisor/get_commodities_by_organization/');
        commit('setCommodityRecords', commodities);
        return commodities;
      } catch (error) {
        this._vm.$snackbar.error(error);
        return false;
      }
    },

    async getDeliveriesByOrganization({ commit }) {
      try {
        const { deliveries } = await postRequest('/budgeting/buyer_advisor/get_deliveries/');
        commit('setDeliveryRecords', deliveries);
        return deliveries;
      } catch (error) {
        this._vm.$snackbar.error(error);
        return false;
      }
    },

    async createDelivery({ dispatch }, payload) {
      try {
        await postRequest('/budgeting/buyer_advisor/create_delivery/', payload);
        await dispatch('getDeliveriesByOrganization');
        this._vm.$snackbar.success('Delivery created successfully');
        return true;
      } catch (error) {
        this._vm.$snackbar.error(error);
        return false;
      }
    },

    async updateDelivery({ dispatch }, payload) {
      try {
        await postRequest('/budgeting/buyer_advisor/update_delivery/', payload);
        await dispatch('getDeliveriesByOrganization');
        this._vm.$snackbar.success('Delivery updated successfully');
        return true;
      } catch (error) {
        this._vm.$snackbar.error(error);
        return false;
      }
    },

    async deleteDelivery({ dispatch }, payload) {
      try {
        await postRequest('/budgeting/buyer_advisor/delete_delivery/', payload);
        await dispatch('getDeliveriesByOrganization');
        this._vm.$snackbar.success('Delivery deleted successfully');
        return true;
      } catch (error) {
        this._vm.$snackbar.error(error);
        return false;
      }
    },

    async getCommentaries({ commit, state }, payload) {
      try {
        const commentaries = await postRequest('/budgeting/buyer_advisor/get_commentaries/', payload);

        commit('setVerifiedCommentary', commentaries['verified']);
        commit('setUnverifiedCommentary', commentaries['not_verified']);

        const indicatorSignals = state.verifiedCommentary.reduce((acc, { signal_type_display, signal_strength }) => {
          if (state.indicatorSignals.includes(signal_type_display)) {
            acc[signal_type_display] = signal_strength;
          }
          return acc;
        }, {});

        commit('setBuyerSmartAdvisorIndicatorSignals', indicatorSignals);
      } catch (error) {
        this._vm.$snackbar.error(error);
      }
    },

    async updateCommentary({ dispatch, state }, payload) {
      try {
        await postRequest('/budgeting/buyer_advisor/update_commentary/', payload);
        await dispatch('getCommentaries', { commodity: state.selectedCommodity.id });
        this._vm.$snackbar.success('Commentary updated successfully');
        return true;
      } catch (error) {
        this._vm.$snackbar.error(error);
        return false;
      }
    },

    async getBuyerSmartAdvisorCotData({ commit }, payload) {
      const url = createMLUrl(MLAPI_ROOT, MLAPI_COT_DATA);

      try {
        const commodities = await MLAPIRequest(url.href, payload);
        commit('setBuyerSmartAdvisorCotData', commodities);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async getBuyerSmartAdvisorInterestRateData({ commit }, payload) {
      const url = createMLUrl(MLAPI_ROOT, MLAPI_INTEREST_RATE_DATA);

      try {
        // TODO: Uncomment this once the API is ready
        // const interest_rates = await MLAPIRequest(url.href, payload);
        // commit('setBuyerSmartAdvisorInterestRateData', interest_rates);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async getBuyerSmartAdvisorFuturesPrices({ commit, state }, payload) {
      const url = createMLUrl(MLAPI_ROOT, MLAPI_FUTURES_PRICES);

      try {
        const { prices } = await MLAPIRequest(url.href, payload);
        Object.entries(prices).forEach(([commodity, priceData]) => {
          prices[commodity] = processPricesForCommodity(priceData);
        });
        commit('setBuyerSmartAdvisorFuturesPrices', prices);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },

    async getBuyerSmartAdvisorIndicatorSignals({ commit }, payload) {
      const url = createMLUrl(MLAPI_ROOT, MLAPI_INDICATOR_SIGNALS);
      try {
        // TODO: Uncomment this once the API is ready
        // const { indicator_signals } = await MLAPIRequest(url.href, payload);
        // commit('setBuyerSmartAdvisorIndicatorSignals', indicator_signals);
      } catch (e) {
        this._vm.$snackbar.error(e);
      }
    },
  },
};
